<template>
  <div class="section">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="isAudio"
      @update="update"
    >
      <div class="section-form">
        <div class="item-left">
          <div class="item-flex">
            <div class="item-flex-label">主体类型：</div>
            <div class="item-flex-value">
              {{ principalTypeMap[form.principalType] }}
            </div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">商户名称：</div>
            <div class="item-flex-value">{{ form.title }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">营业执照照片：</div>
            <div class="item-flex-value">
              <!-- {{ form.businessLicense }} -->
              <v-upload
                :limit="9"
                disabled
                :imgUrls.sync="form.businessLicense"
              />
            </div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">注册号/统一社会信用代码：</div>
            <div class="item-flex-value">
              {{ form.taxpayerRegistrationNumber }}
            </div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">个体户经营者/法人姓名：</div>
            <div class="item-flex-value">{{ form.legalPersonName }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">经营者/法人身份证件：</div>
            <div class="item-flex-value">{{ form.idNumber }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">经营者/法人身份证件正反面：</div>
            <div class="item-flex-value">
              <!-- {{ form.idCardPhotoFront }}{{ form.idCardPhotoBack }} -->
              <v-upload
                :limit="1"
                disabled
                :imgUrls.sync="form.idCardPhotoFront"
              />
              <v-upload
                :limit="1"
                disabled
                :imgUrls.sync="form.idCardPhotoBack"
              />
            </div>
          </div>

          <el-form-item
            label="审核状态"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'blur' },
            ]"
            prop="auditStatus"
          >
            <v-select
              :disabled="!isAudio"
              :width="width"
              :options="auditStatusOps"
              v-model="form.auditStatus"
            />
          </el-form-item>
        </div>
        <div class="item-right">
          <div class="item-flex">
            <div class="item-flex-label">商户简称：</div>
            <div class="item-flex-value">{{ form.titleAbbreviation }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">联系电话：</div>
            <div class="item-flex-value">{{ form.mobileNum }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">所属行业：</div>
            <div class="item-flex-value">{{ form.industryType }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">账户类型：</div>
            <div class="item-flex-value">
              {{ accountTypeMap[form.accountType] }}
            </div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">开户名称：</div>
            <div class="item-flex-value">{{ form.accountName }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">开户银行：</div>
            <div class="item-flex-value">{{ form.accountBank }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">开户银行省市编码：</div>
            <div class="item-flex-value">{{ form.accountBankCityCode }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">开户银行全称（含支行）：</div>
            <div class="item-flex-value">{{ form.accountBankName }}</div>
          </div>
          <div class="item-flex">
            <div class="item-flex-label">银行账号：</div>
            <div class="item-flex-value">{{ form.bankCard }}</div>
          </div>
        </div>
      </div>
    </form-panel>
  </div>
</template>

<script>
import { auditGroupLeaderURL, getGroupLeaderDetailsURL } from "./api.js";
import {
  // shelfStsMap,
  // setShelfStsOps,
  goodsTypeMap,
  // setGoodsTypeOps,
  // auditStatusMap,
  setAuditStatusOps,
  principalTypeMap,
  accountTypeMap,
  // setPrincipalTypeOps,
} from "./map.js";

export default {
  data() {
    return {
      width: 250,
      isAudio: false,
      submitConfig: {
        queryUrl: getGroupLeaderDetailsURL,
        submitUrl: auditGroupLeaderURL,
        submitContentType: "application/x-www-form-urlencoded;charset:utf-8;",
      },
      communityOps: [],
      // shelfStsMap,
      // auditStatusMap,
      goodsTypeMap,
      // goodsTypeOps: setGoodsTypeOps(),
      auditStatusOps: setAuditStatusOps(),
      principalTypeMap,
      accountTypeMap,
      // principalTypeOps: setPrincipalTypeOps(),
      form: {
        id: "",
        principalType: "",
        title: "",
        businessLicense: "",
        taxpayerRegistrationNumber: "",
        legalPersonName: "",
        idNumber: "",
        idCardPhotoFront: "",
        idCardPhotoBack: "",
        titleAbbreviation: "",
        mobileNum: "",
        industryType: "",
        accountType: "",
        accountName: "",
        accountBank: "",
        accountBankCityCode: "",
        accountBankName: "",
        bankCard: "",
        auditStatus: 0,
      },
    };
  },
  async mounted() {
    const { id, isAudio, isView } = this.$route.query;
    this.form.id = id;
    this.isAudio = !!isAudio;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
    }
    this.$setBreadList(isAudio ? "审核" : "查看");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore() {
      this.$refs.formPanel.setSubmitDisabled(true);
      this.$axios
        .post(auditGroupLeaderURL, null, {
          params: {
            id: this.form.id,
            auditStatus: this.form.auditStatus,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.$refs.formPanel.setSubmitDisabled(false);
        });
      return false;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
  .section-form {
    display: flex;
  }
  .item-left {
    width: 50%;
    max-width: 50%;
  }
  .item-right {
    width: 50%;
    max-width: 50%;
  }
  .item-flex {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .item-flex-label {
      width: 220px;
      min-width: 220px;
      font-size: 14px;
      line-height: 40px;
      color: #606266;
      text-align: right;
      white-space: nowrap;
    }
    .item-flex-value {
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
      line-height: 28px;
      display: flex;
    }
  }
}
</style>
